@import '../../config';

.base {
    display: inline;
    margin-right: 1rem;
    position: relative;

    > svg {
        color: #ffffff;
        font-size: .625rem;
        left: 5px;
        position: absolute;
        pointer-events: none;
        top: 3px;
        z-index: 2;
    }

    input {
        margin-right: calc(1rem - 2px);
        opacity: 0;
    }

    label {
        cursor: pointer;

        &:before {
            background-color: transparent;
            border-radius: $borderRadius;
            border: solid 2px #dbdfea;
            content: '';
            height: 1rem;
            left: 0;
            position: absolute;
            top: -2px;
            transition:
                background-color 150ms linear,
                border-color 150ms linear,
                box-shadow 150ms linear;
            width: 1rem;
            z-index: 1;
        }
    }

    input:focus + label {
        &:before {
            border-color: $colorRocheBlue;
            box-shadow: 0 0 0 3px $colorBlue10;
        }
    }
    
    input:checked + label {
        &:before {
            background-color: $colorRocheBlue;   
            border-color: $colorRocheBlue;
        }
    }

    &.radio {
        > svg {
            font-size: .5rem;
            left: 6px;
            top: 4px;
        }

        label:before {
            border-radius: 100%;
        }
    }
}
