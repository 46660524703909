@import '../../RbKit/config';

.base {
    background: #ffffff;
    
    * {
        font-weight: normal !important;
    }

    h2 {
        font-family: "minion-pro", serif !important;
        font-weight: 400 !important;
        font-style: italic !important;
    }
}

.container {
    // background: #bed8ea;
    // padding: 2rem;
    margin-bottom: 2rem;
    .col2 {
        margin-bottom: 2rem;
    }

    @include portrait {
        display: flex;

        .col1 {
            flex-basis: 31.11111111111111%;
            margin-left: 3.333333333333333%;
        }

        .col2 {
            flex-basis: 65.55555555555556%;
        }
    }
}

.badge {
    display: inline-block;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    position: relative;
    top: 0;
    left: 5px;
    background-color: $colorRocheGreen;

    &.badgeOffline {
        background-color: $colorRocheRed;
    }
}

.alerts {
    .alert {
        background-color: #feeacd;
        border-left: solid .5rem #fd9927;
        margin-bottom: 1rem;
    }

    .alertSuccess {
        background-color: #e7ffe7;
        border-left: solid .5rem $colorRocheGreen;
    }
}

.personas {
    @include portrait {
        display: flex;
        flex-wrap: wrap;
    }
    margin: 1rem -.5rem;

    .persona {
        flex-basis: 25%;

        > div {
            cursor: pointer;
            background-color: $colorRocheBlue;
            border: solid 1px $colorRocheBlue;
            border-radius: 6px;
            color: #ffffff;
            margin: 0 1rem 1rem 0;
            text-align: center;
            font-weight: 500 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            margin: 0 .5rem .5rem .5rem;
            transition: all 150ms linear;
            font-size: 18px;

            &.isActive, &:hover {
                background-color: #fff;
                color: $colorRocheBlue;
            }
        }
    }
}

.searchBar {
    margin-bottom: 2rem;
    position: relative;
    z-index: 3;

    .searchInput {
        display: flex;

        > div {
            flex: 1;
            margin: 0;
        }

        button {
            background: $colorRocheBlue;
            color: #ffffff;
            font-weight: 700;
            padding: 0 24px;
            border: 0;
        }
    }
    
    .searchLoader {
        position: absolute;
        z-index: 3;
        right: 1rem;
        top: 1rem;
        font-size: 1rem;
        color: $colorRocheBlue;
    }

    .searchResults {
        background: $colorWhite;
        border: solid 1px $colorRocheBlue;
        border-radius: 2px;
        position: absolute;
        margin-top: -1px;
        top: 3.5rem;
        width: 100%;

        > div {
            border-bottom: solid 1px $colorGrey20;
            color: $colorRocheBlue;
            cursor: pointer;
            padding: 1rem;
            font-family: "fontPrimary", "arial-rfb", sans-serif;
            font-weight: 400;
            font-style: italic;
            font-size: 20px;
            line-height: 28px;

            &:hover {
                background-color: $colorBlue5;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.stickies {
    @include portrait {
        display: flex;
        flex-wrap: wrap;
    }

    .sticky {
        flex-basis: 100%;
        flex: 1;
        @include portrait {
            flex-basis: 50%;
        }

        > div {
            color: $colorRocheBlue;
            cursor: pointer;
            display: flex;
            padding: 0 1rem;

            svg {
                font-size: 1rem;
                margin-right: 1rem;
                position: relative;
                margin-top: 6px;
                left: 0;
                transition: left 150ms linear;
            }

            &:hover svg {
                left: 5px;
            }

            p {
                flex: 1;
                // font-weight: 500 !important;
                margin: 0;
            }
        }
    }
}

.otherOptions {
    // @include portrait {
    //     display: flex;
    //     justify-content: flex-start;
    // }
    margin: 2rem -.5rem 0 -.5rem;

    h2 {
        
    }

    .option {
        margin: .5rem;
        flex: 1;
        border: solid 1px $colorGrey20;
        padding: .5rem;
        border-radius: 6px;
        display: flex;
        align-items: center;

        .optionIcon {
            padding: 1rem 1rem 1rem .5rem;
            color: $colorRocheBlue;

            svg {
                font-size: 2rem;
                color: $colorRocheBlue;
            }
        }

        .optionInfo {
            flex: 1;
            color: #333333;
            line-height: 1.4;
            max-width: 190px;

            a {
                line-height: 1 !important;
            }

            b {
                font-size: 1rem;
                color: $colorRocheBlue;
                display: block;
                text-transform: uppercase;
                font-weight: 500 !important;
            }
        }
    }
}

.thumbs {
    padding-top: .5rem;

    button {
        background: transparent;
        border: 0;
        padding: 0;
        font-size: 32px;
        color: $colorRocheBlue;
        margin-right: 1.5rem;

        svg {
            display: block;
            &:last-child {
                display: none;
            }
        }

        &:hover svg {
            display: none;
            &:last-child {
                display: block;
            }
        }
    }
}
