@import '../../RbKit/config';

.container {
    background: $colorDeepBlue;
    box-shadow: none;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms linear;
    z-index: 200;
    
    > div:first-child {
        h1 {
            color: $colorWhite;
        }
    }

    p {
        color: $colorWhite;
        font-weight: 700;
        margin: 0 2rem 0 0;
    }

    &.show {
        opacity: 1;
        pointer-events: inherit;
    }
}
