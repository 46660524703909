@import '../../../RbKit/config';

.container {
    @include fullCenter;
    height: 100vh;

    > div {
        max-width: 400px;
    }
}

.btnContainer {
    @include alignRight;
    margin-top: 1rem;
}
