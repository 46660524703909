.container {
    > div {
        float: left;
    }
}

.modalUploadButton {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0 0;
}
