@import './config/colors';
@import './config/elevations';

// screen
$screenPortrait: 768px;
$screenLandscape: 992px;
$screenDesktop: 1200px;

@mixin portrait {
    @media (min-width: #{$screenPortrait}) {
        @content;
    }
}

@mixin landscape {
    @media (min-width: #{$screenLandscape}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$screenDesktop}) {
        @content;
    }
}

@mixin custom($width) {
    @media (min-width: $width+'px') {
        @content;
    }
}

@mixin customMax($width) {
    @media (max-width: $width+'px') {
        @content;
    }
}

// settings
$borderRadius: .3125rem;

// alignment
@mixin alignCenter {
    display: flex;
    justify-content: center;
}

@mixin alignRight {
    display: flex;
    justify-content: flex-end;
}

@mixin fullCenter {
    align-items: center;
    display: flex;
    justify-content: center;
}
