$colorBlack: #000000;
$colorRocheBlue: #0066cc;
$colorWhite: #ffffff;
$colorWhite80: rgba(255, 255, 255, .8);
$colorWhite70: rgba(255, 255, 255, .7);
$colorGrey90Alpha: rgba(0, 0, 0, .9);
$colorGrey80Alpha: rgba(0, 0, 0, .8);
$colorGrey60Alpha: rgba(0, 0, 0, .6);
$colorGrey50Alpha: rgba(0, 0, 0, .5);
$colorGrey30: #b2b2b2;
$colorGrey20: #cccccc;
$colorGrey10: #e5e5e5;
$colorGrey5: #f2f2f2;
$colorGrey2: #fafafa;
$colorBackground: #f5f6fA;
$colorBlue60: #66a3e0;
$colorBlue40: #99c2eb;
$colorBlue30: #b2d1f0;
$colorBlue20: #cce0f5;
$colorBlue10: #e5effA;
$colorBlue5: #f2f7fC;
$colorError: #D0021b;
$colorSuccess: #008000;
$colorWarning: #ffa500;
$colorRocheRed: #D0021b;
$colorRocheViolet: #a05eb5;
$colorRocheGreen: #00965e;
$colorRocheCyan: #00e5eF;
$colorRocheOrange: #ed8b00;
$colorRocheYellow: #ffc72c;
$colorDeepBlue: #001429;
$colorDeepBlue95: #0d1f33;
$colorDarkBlue: #004db0;

$gradientRocheBlue: linear-gradient(135deg, #006bff 0%, $colorRocheBlue 100%);
