@import '../../config';

.base {
    display: flex;
    margin-bottom: 2rem;

    div:first-child {
        flex: 1;
        margin-right: 1rem;
    }

    h2 {
        margin-bottom: .5rem;
    }
}

.breadcrumb {
    margin: -.5rem 0 .5rem 0;

    a {
        color: $colorGrey90Alpha;
        font-size: .75rem;
        text-decoration: none;
        transition: color 150ms linear;

        &:hover {
            color: $colorRocheBlue;
        }

        svg:last-child {
            color: $colorGrey90Alpha;
            font-size: .5rem;
            margin: 0 .5rem;
        }
    }

    a:last-child {
        color: $colorRocheBlue;
        svg { display: none }
    }
}
